import React from 'react';
import { Icon, Label } from 'semantic-ui-react';

import {
	severancePay,
	annualLeavesPay,
	compensationVacationPay,
	yearEndRefundPay,
} from '../../../../../app/api/dropdownOption';

import MySelectInput from '../../../../../app/common/form/MySelectInput';
import moment from 'moment/moment';
import MyTextArea from '../../../../../app/common/form/MyTextArea';
import { openModal } from '../../../../../app/common/modals/modalReducer';
import { useDispatch } from 'react-redux';

export default function CrwfWorkerRetireInfo43({
	numOfWorkers,
	selectedClientReq4In,
	retiredDate,
	paydocus,
	workerEnteredDate,
	workerRetiredDate,
	// values,
}) {
	const dispatch = useDispatch();

	const enteredDateM = moment(workerEnteredDate);
	const retiredDateM = moment(workerRetiredDate);

	const hiredPeriod = retiredDateM.diff(enteredDateM, 'month');
	const overOneYear = hiredPeriod >= 12 ? true : false;
	const overOneMonth = hiredPeriod >= 1 ? true : false;

	const hourOrdWageArray = paydocus
		? paydocus.map((m) => m?.fixedWage?.hourOrdinaryWage)
		: [];
	const lastHourOrdWage = hourOrdWageArray
		? hourOrdWageArray[hourOrdWageArray.length - 1]
		: '';
	const dayLawBaseTime = selectedClientReq4In?.realWorkTime?.days1LBTime;
	function workerRegStep4_3YTOpen() {
		dispatch(openModal({ modalType: 'WorkerRegStep4_3' }));
	}
	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'flex-start',
				alignItems: 'center',
				alignContent: 'flex-end',
			}}>
			<br />
			<br />
			<div>
				{!overOneMonth ? '근속년수 1개월 미만자' : '근속년수 1개월 이상자'}
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					alignContent: 'flex-end',
					width: '100%',
					marginBottom: '30px',
					marginTop: '30px',
					fontSize: '17px',
				}}>
				<span style={{ color: 'red' }}>* </span>
				<span style={{ color: 'blue' }}>퇴직 금품청산</span>
				<span style={{ fontSize: '8px' }}>
					(설명은 오른쪽 동영상을 참조하세요.)
				</span>
				<span
					style={{
						position: 'absolute',
						right: 0,
						marginRight: '10px',
						cursor: 'pointer',
					}}>
					<Label
						style={{ color: 'red' }}
						onClick={() => workerRegStep4_3YTOpen()}>
						<Icon size='big' name='youtube' />
						퇴직 금품청산 설명
					</Label>
				</span>
			</div>

			{numOfWorkers >= 5 && (
				<>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MySelectInput
							label='연차휴가 정산'
							name='annualLeavesPay'
							placeholder='있으면 퇴사 14일이내 지급원칙'
							options={annualLeavesPay}
							disabled={!retiredDate || !overOneMonth}
						/>
						{paydocus && lastHourOrdWage ? (
							<Label color='blue'>
								1일분 미사용 연차수당액 :{' '}
								{lastHourOrdWage
									? (lastHourOrdWage * dayLawBaseTime).toLocaleString('en-GB')
									: ''}
								원
							</Label>
						) : null}
					</div>
					<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
						<MySelectInput
							label='보상휴가 정산 여부 등'
							name='compensationVacationPay'
							placeholder='있으면 퇴사 14일이내 지급원칙'
							options={compensationVacationPay}
							disabled={!retiredDate || !overOneMonth}
						/>
						{paydocus && lastHourOrdWage ? (
							<Label color='blue'>
								시간급 통상임금 :{' '}
								{lastHourOrdWage ? lastHourOrdWage.toLocaleString('en-GB') : ''}
								원
							</Label>
						) : null}
					</div>
				</>
			)}
			<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
				<MySelectInput
					label='연말정산 환급금 정산'
					name='yearEndRefundPay'
					placeholder='있으면 퇴사 14일이내 지급원칙'
					options={yearEndRefundPay}
					disabled={!retiredDate}
				/>
				<Label></Label>
			</div>
			<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
				<MySelectInput
					label='퇴직금 정산'
					name='severancePay'
					placeholder='있으면 퇴사 14일이내 지급원칙'
					options={severancePay}
					disabled={!retiredDate || !overOneYear}
				/>
			</div>

			<div className='margin' style={{ width: '99%', marginRight: '3px' }}>
				<MyTextArea
					label='기타 메모 및 특이사항(선택)'
					name='comment4InsureOutMemo'
					placeholder='기타 메모.'
					rows={2}
				/>
			</div>
		</div>
	);
}
